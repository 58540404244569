import React, { useState } from 'react';
import './Card.css';

export const Card = ({ search, defaultText }) => {
    const [text, setText] = useState(defaultText || '');

    const handleClick = () => {
        console.log(text);
        setText(text);
        search(text);
    }

    return (
        <div className='Card'>
            <div className='Header'>
                Building your future<br />
                <span className='GreenText'> AI clinical assistant</span>
            </div>
            <div className='SearchContainer'>
                <div className='InputQuestionDiv' >
                    <textarea
                        rows={5}
                        cols={5}
                        className='InputQuestion'
                        placeholder="Ask your question..."
                        value={text}
                        onChange={(e) => setText(e.target.value)} />
                </div>
            </div>
            <div className='Button' onClick={handleClick}>Submit your question</div>
        </div>
    )
}
