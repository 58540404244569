import './ArticleCard.css';
import { useState } from 'react';
export const ArticleCard = ({ score, title, date, authors, journal, insight, results }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className={`ArticleCard ${isCollapsed ? 'collapsed' : ''}`} onClick={toggleCollapse}>
            <div className='CollapseIcon'>
                {isCollapsed ? '▼' : '▲'}
            </div>
            <div className='Body'>
                <div className='Title'>{title}</div>
                {!isCollapsed && (
                    <>
                        <div className='Container'>
                            <div className='Subheader'>Date:</div>
                            <div className='Headervalue'>{date}</div>
                        </div>
                        <div className='Container'>
                            <div className='Subheader'>Trust Score:</div>
                            <div className='Headervalue'>{score.toFixed(3)}</div>
                        </div>
                        <div className='Container'>
                            <div className='Subheader'>Author(s):</div>
                            <div className='Headervalue'>{authors}</div>
                        </div>
                        <div className='Container'>
                            <div className='Subheader'>Journal:</div>
                            <div className='Headervalue'>{journal}</div>
                        </div>
                        <div className='Insight'>
                            <div className='InsightHeader'>Key Insights</div>
                            <div className='InsightValue'>{insight}</div>
                        </div>
                        <div className='Insight'>
                            <div className='InsightHeader'>Analysis Results</div>
                            <div className='InsightValue'>{results}</div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};