import './TrustCard.css'

const TrustCard = ({synthesisResult}) => {
    return (
        <div className='TrustCard'>
            <div>
                <div className='Header'>Results</div>
                <div className='Text'>{synthesisResult}</div>
            </div>
            {/* <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="257" height="257" viewBox="0 0 257 257" fill="none">
                    <path d="M14 128.501C14 113.464 16.9616 98.5751 22.7158 84.6833C28.47 70.7916 36.904 58.1692 47.5363 47.5369C58.1686 36.9046 70.791 28.4705 84.6828 22.7164C98.5745 16.9622 113.464 14.0006 128.5 14.0006C143.536 14.0006 158.425 16.9622 172.317 22.7164C186.209 28.4706 198.831 36.9046 209.464 47.5369C220.096 58.1692 228.53 70.7916 234.284 84.6834C240.038 98.5752 243 113.464 243 128.501C243 143.537 240.038 158.426 234.284 172.318C228.53 186.21 220.096 198.832 209.464 209.464C198.831 220.097 186.209 228.531 172.317 234.285C158.425 240.039 143.536 243.001 128.5 243.001C113.464 243.001 98.5745 240.039 84.6827 234.285C70.7909 228.531 58.1685 220.097 47.5362 209.464C36.9039 198.832 28.4699 186.21 22.7158 172.318C16.9616 158.426 14 143.537 14 128.501L14 128.501Z" stroke="#F2F2F2" stroke-width="28" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14 128.501C14 106.356 20.4217 84.6858 32.4872 66.1163C44.5527 47.5468 61.7442 32.8747 81.979 23.8772C102.214 14.8798 124.624 11.9432 146.493 15.4232C168.363 18.9033 188.755 28.6506 205.197 43.4844C221.64 58.3181 233.428 77.6017 239.134 98.999C244.84 120.396 244.218 142.989 237.345 164.04C230.471 185.092 217.64 203.698 200.407 217.605C183.174 231.512 162.277 240.124 140.249 242.396" stroke="url(#paint0_radial_4192_8941)" stroke-width="28" stroke-linecap="round" stroke-linejoin="round"/>
                    <defs>
                        <radialGradient id="paint0_radial_4192_8941" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.3848 14.0006) rotate(52.7956) scale(287.514)">
                        <stop offset="0.312224" stop-color="#009818"/>
                        <stop offset="0.676175" stop-color="#FFB800"/>
                        <stop offset="0.921988" stop-color="#FE1E00"/>
                        </radialGradient>
                    </defs>
                    <text x="50%" y="50%" text-anchor="middle" dominant-baseline="middle" 
                          fill="#000" 
                          fontFamily="Roboto" 
                          fontSize="91" 
                          fontStyle="normal" 
                          fontWeight="700" 
                          lineHeight="normal" 
                          letterSpacing="-4.55px">
                        78
                    </text>
                    <text x="70%" y="30%" dy="0.35em" text-anchor="start" dominant-baseline="middle" 
                          fill="#000" 
                          fontFamily="Roboto" 
                          fontSize="27" 
                          fontStyle="normal" 
                          fontWeight="400" 
                          lineHeight="normal" 
                          letterSpacing="-0.54px">
                        %
                    </text>
                </svg>
            </div> */}
        </div>
    )
}

export default TrustCard
